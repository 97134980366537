import Vue from "vue";
import VueRouter from "vue-router";
import LayoutDocs from "@/layouts/LayoutDocs.vue";
import LayoutMain from "@/layouts/LayoutMain.vue";
import Home from "@/views/Home.vue";
import OminiPresentation from "@/views/omini/OminiPresentation.vue";
import TalkPresentation from "@/views/talk/TalkPresentation.vue";
import SystemIntegrationPresentation from "@/views/systemIntegration/SystemIntegrationPresentation.vue";
//import WhatsappPresentation from "@/views/whatsapp/WhatsappPresentation.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: LayoutMain,
    children: [
      {
        name: "Home",
        path: "home",
        component: Home,
      },
    ],
  },
  {
    path: "/docs",
    redirect: "/home",
    component: LayoutDocs,
    children: [
      {
        name: "OminiPresentation",
        path: "omini-presentation",
        component: OminiPresentation,
      },     
      {
        name: "TalkPresentation",
        path: "talk-presentation",
        component: TalkPresentation,
      },
      {
        name: "SystemIntegrationPresentation",
        path: "system-integration-presentation",
        component: SystemIntegrationPresentation,
      },
      {
        name: "OminiDocs",
        path: "omini-docs",
        beforeEnter() {
          location.href =
            "https://documenter.getpostman.com/view/18770827/2s9YeK3VXh";
        },
      },
      {
        name: "TalkDocs",
        path: "talk-docs",
        beforeEnter() {
          location.href =
            "https://documenter.getpostman.com/view/18770827/2s9YsJDDaR";
        },
      },
      {
        name: "SystemIntegrationDocs",
        path: "system-integration-docs",
        beforeEnter() {
          location.href =
            "https://documenter.getpostman.com/view/18770827/2s9YsKfrZ1#intro";
        },
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
});

export default router;
