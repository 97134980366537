
import { Vue, Component } from "vue-property-decorator";
import { translate } from "./../plugins/i18n/index";
@Component
export default class Cards extends Vue {
  hover = false;
  get cards() {
    return [
      {
        id: 2,
        title: `${translate("omini")}`,
        textButton: `${translate("seeDocumentation")}`,
        route: "OminiPresentation",
        image: require("../assets/logos/omini.svg"),
      },
      {
        id: 1,
        title: `${translate("talk")}`,
        textButton: `${translate("seeDocumentation")}`,
        route: "TalkPresentation",
        image: require("../assets/logos/talk.svg"),
      },
      {
        id: 3,
        title: `${translate("systemIntegration")}`,
        textButton: `${translate("seeDocumentation")}`,
        route: "SystemIntegrationPresentation",
        image: require("../assets/logos/talk.svg"),
      },
    ];
  }
}
