
import { Vue, Component } from "vue-property-decorator";

import { translate } from "@/plugins/i18n/index";
@Component({
  components: { ApiSideBar },
})
export default class ApiSideBar extends Vue {
  get items() {
    return [
     /*{
        id: 3,
        name: "WHATSAPP",
        route: "WhatsappPresentation",
        icon: "mdi-whatsapp",
        child: [
          {
            id: 1,
            name: `${translate("presentation")}`,
            route: "WhatsappPresentation",
          },
          {
            id: 2,
            name: `${translate("documentation")}`,
            route: "WhatsappDocs",
            target: "_blank",
          },
        ],
      },*/
      {
        id: 1,
        name: `${translate("chatbot")}`,
        icon: "mdi-face-agent",
        route: "OminiPresentation",
        child: [
          {
            id: 1,
            name: `${translate("presentation")}`,
            route: "OminiPresentation",
          },
          {
            id: 2,
            name: `${translate("documentation")}`,
            route: "OminiDocs",
            target: "_blank",
          },
        ],
      },

      {
        id: 2,
        name: `${translate("voice")}`,
        route: "TalkPresentation",
        icon: "mdi-phone",
        child: [
          {
            id: 1,
            name: `${translate("presentation")}`,
            route: "TalkPresentation",
          },
          {
           id: 2,
           name: `${translate("documentation")}`,
           route: "TalkDocs",
           target: "_blank"
           },
        ],
      },

      {
        id: 3,
        name: `${translate("systemIntegrationSideBar")}`,
        route: "SystemIntegrationPresentation",
        icon: "mdi-lan-pending",
        child: [
          {
            id: 1,
            name: `${translate("presentation")}`,
            route: "SystemIntegrationPresentation",
          },
          {
           id: 2,
           name: `${translate("documentation")}`,
           route: "SystemIntegrationDocs",
           target: "_blank"
           },
        ],
      },
    ];
  }

  get Sidebar_drawer() {
    return this.$store.state.Sidebar_drawer;
  }
  set Sidebar_drawer(val: string) {
    this.$store.commit("SET_SIDEBAR_DRAWER", val);
  }
}
